import { ClockIcon, VideoCameraIcon, CalendarIcon, GlobeAltIcon } from '@heroicons/react/outline';
// heroBannerData.js
export const pagedata = {
  id: 1,
  slug: "ayushi",
  typingText: "Score More Study Less",
  heroData: {
    title: [
      { id: 1, text: "SCORE MORE" },
      { id: 2, text: " Study Less", className: "text-white-color" },
    ],
    typingTitle: [
        { id: 1, text: "SCORE MORE" },
        { id: 2, text: ["STUDY LESS", "MANAGE TIME", 'NO DISTRACTION', 'MORE FOCUS'], className: "text-white-color" },
      ],
    description: [
      { id: 2, text: "Get ready to Study like a ", className: "text-white-color" },
      { id: 1, text: "Ranker" },
      { id: 3, text: " in just ", className: "text-white-color" },
      { id: 4, text: "5 days!" },
    ],
    videoId: "949108417",
    videoUrl: "https://iframe.mediadelivery.net/embed/228263/515051d6-105d-4e68-b622-9d5df4df1deb",
    videoThumbnailUrl: "https://vz-915da578-c14.b-cdn.net/515051d6-105d-4e68-b622-9d5df4df1deb/thumbnail_4dd17cf0.jpg",
    backgroundImageUrl: "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e0ab00a6319_header-background-image.jpg",
    eventDetails: {
      influencerName: "Aayushi",
      influencerShortBio: "15000+ Students | Chartered Accountant | First Attempt | CFA L1 & CS Executive | Ex-PwC",
      influencerBannerImage: "https://toppernotes.s3.ap-south-1.amazonaws.com/images/kJ2q5dgmpnX17varc399.png",
      numberOfDays: 5,
      typeSessions: "Live + Recorded",
      startDate: "1st July 2024",
      registerTill: "15/07/2024 19:00:00",
      registerTillZone: "August 23, 2022 17:00:00 PDT",
      timeOfSessions: "6 to 9PM",
      joinButtonLabel: "Register now at just",
      salePrice: 299,
      originalPrice: 1999,
      bonusesWorth: 4999,
      joinTagLine: "Demo tag line",
      seatsLeft: 49,
      totalSeats: 100,
      bottomButtonText: "Reserve Now",
      eventtype: 'masterclass',
      eventTimer: {
        days: 12,
        hours: 12,
        minutes: 34,
        seconds: 12
      },
      title: [
        { id: 1, text: "Join us for the ", className: "text-black-color" },
        { id: 2, text: "Workshop" },
      ],
      eventChips: [
        {
          imgSrc: "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e6aef0a6318_Vectorclock.svg",
          alt: "Clock Icon",
          icon: ClockIcon,
          heading: `5 Days`,
        },
        {
          imgSrc: "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e39520a6335_Vectortv-.svg",
          alt: "Live Icon",
          icon: VideoCameraIcon,
          heading: "Live + Recorded",
        },
        {
          imgSrc: "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e82bd0a6336_Vectorcalender.svg",
          alt: "Calendar Icon",
          icon: CalendarIcon,
          heading: "8th July",
        },
        {
          imgSrc: "https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e494a0a6337_Vectorclock%20(1).svg",
          alt: "Time Icon",
          icon: GlobeAltIcon,
          heading: "English",
        },
      ]
    }
  },
  theme: {
    style: 'light', // 'light'
    gradient1: '#3e3ef8',
    gradient2: '#673ab7',
    accordion: '#3e3ef8',
    accordionChipFill: '#3e3ef8',
    accordionChipText: '#fff',
    accordionHeader: '#fff',
    primary: '#8217ee',
    secondary: '#e0257e',
    textColorButton: '#fff',
    imageFillColor: '#fff',
    bgcolorchip: {
      light: '#3e3ef840', // Color for light theme
      dark: '#121212'   // Color for dark theme
    },
    bgcolorcontainer: {
      light: '#3e3ef840', // Color for light theme
      dark: '#1a1a1a'   // Color for dark theme
    }
  },
  brands: {
  },
  superMentorData: {
    pagetitle: "Meet Aayushi",
    mentorName: "Aayushi",
    title:
      "<strong>Chartered Accountant,</strong> CFA Level 1 &amp; CS Executive. She is <strong>Ex-PwC</strong>",
    bio: [
      "She has cleared <strong>CA in her first attempt</strong> and holds CFA Level 1 and CS Executive certifications.",
      "Beyond academics, Aayushi focuses on personal growth, her training has impacted <strong>over 25,000 people</strong>, making her a significant force in education.",
      "<strong>Formerly with PwC</strong>, she aims to help 100,000 students transform their lives through her workshops in the next two years.",
      "Award-winning coach, recognized with the prestigious <strong>Unicorn Coach Award</strong> and the <strong>Crore Club Award</strong>.&nbsp;",
      "She has delivered impactful <strong>offline sessions</strong> at esteemed institutions like <strong>NAHATA Academy</strong> in Indore and <strong>Inspire Academy</strong> in Pune. Her expertise extends to online platforms, where she has conducted sessions for <strong>National IAS Academy, CICASA, WICASA, NIRC</strong>, and other renowned organizations.",
    ],
    socialCounts: {
      instagram: "11.4K",
      youtube: "3.2K",
      linkedin: "7K",
    },
    images: {
      mainImage:
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/WhatsApp%20Image%202024-07-08%20at%202.59.48%20PM.jpeg",
      sliderImages: [
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_8696-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/B612_20240718_113705_354-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_8632-ezgif.com-resize.webp",
        "https://toppersnotes-videos.b-cdn.net/webinars-programs/ayushi-pics/IMG_5715-ezgif.com-resize.webp",
      ],
    },
  },
  imagetestimonials: {
    title: [
      { id: 2, text: "Love" },
      { id: 1, text: " Notes from Our", className: "text-white-color" },
      { id: 3, text: " Students" },

    ],
    content: [
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/o4dQyN7IYOcvNRVcVSC3.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/tSfkN27iUN2whz3wfolY.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/9UIsf3k2IAAH8LsAJC2J.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/MN3yU8Nu1dd1xgaiZm8o.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/TFQfPDMmA4kejU0gAbRw.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/qw3iSejQ2jKIKjQF2h1r.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/tlVTYtVNRtQ7FrurbpFs.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/YTHQh03oix7fhZTxNM5r.webp'
      },
      {
        image: 'https://d14a823tufvajd.cloudfront.net/images/ZIFVhmwsd4pC6xoxbPl4.webp'
      }
    ]
  },
  learninggoals: {
  },
  targets: {
    title: [
      { id: 2, text: "Should attend" },
      { id: 1, text: " if you are", className: "text-white-color" },
    ],
    content: [
      {
        person_type: "Students Above 14 Years",
        purpose: `
                <p>
                Ideal for students preparing for national and international exams such as CA, CS, CMA, CFA, UPSC, ACCA, NEET, IIT JEE, CLAT, SAT, GRE, and GMAT. Learn to master waking up early, focusing sharply, and studying efficiently to excel in your exams.
                </p>`
      },
      {
        person_type: "Working Professionals",
        purpose: `
                <p>
                Tailored for professionals eyeing further studies or big exams. Equip yourself with tools to effectively manage your time, reduce work-study conflicts, and enhance focus for high-stakes exams like UPSC, CAT, GMAT, GRE, or professional certifications..
                </p>`
      },
      {
        person_type: "Housewives & Young Mothers",
        purpose: `
                <p>
                Designed for those balancing family duties with personal academic goals. Learn time management strategies and focus techniques that enable you to prepare efficiently for exams such as UPSC, CA, or language certifications, all while managing your home..
                </p>`
      },
      {
        person_type: "Teachers",
        purpose: `
                <p>
                For educators seeking to deepen their expertise and teaching methodologies. This workshop provides strategies to increase mental clarity, reduce professional burnout, and enhance skills, helping you and your students achieve greater academic success..
                </p>`
      },
      {
        person_type: "Retired Individuals",
        purpose: `
                <p>
                Encourage continuous intellectual engagement by adopting effective study habits tailored for lifelong learning. Gain strategies to enhance focus and productivity, foster new interests, and prepare for any form of academic or hobbyist exams with renewed enthusiasm. This approach instills in you a new zeal to manifest your unfulfilled dreams and desires.
                </p>`
      },
      {
        person_type: "Resuming Studies, Kickstarters",
        purpose: `
                <p>
                Ideal for those restarting their education after a break. This workshop equips you with strategies to rekindle your study habits, efficiently manage time, and overcome mental hurdles. Rediscover your potential and prepare effectively for your next academic or professional milestone..
                </p>`
      }
    ]
  },
  nottargets: {
  },
  numbers: {
    title: [
      { id: 2, text: "Numbers" },
      { id: 1, text: " speaking", className: "text-white-color" },
    ],
    content: [
      { id: 1, type: "title-first", heading: "15,100+", title: "Students Enrolled", images: ["https://assets-global.website-files.com/64ede0a591d8114fdaa0ae7f/65eae333ddc74095430422cb_User%20images%20(2).webp"] },
      { id: 2, type: "image-first", heading: "4.8/5", title: "Google Ratings (580+)", images: ["https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg"] },
      { id: 5, type: "bigger-card", heading: "Free Bonuses worth ₹4999", title: "", images: ["https://d14a823tufvajd.cloudfront.net/images/AYqKg0OGZ3vwPp3ZrWn6.png"] }
    ]
},
    about : {
        title: [
            { id: 1, text: "Meet", className: "text-white-color"},
            { id: 2, text: " Aayushi" },
        ],
        bio: "<p>Aayushi Garg, a <strong>Chartered Accountant</strong> and <strong>Passionate Trainer</strong> &amp; <strong>Career Coach</strong>, spearheads a mission to revolutionize how India studies. With a focus on <strong>efficient</strong> and <strong>smart study techniques</strong>, Aayushi <strong>empowers students</strong> to ace exams <strong>without exhaustive hours of study</strong>. Her impact extends to people of <strong>all ages,&nbsp;&nbsp;</strong>students, working professionals, and housewives. Guiding them to achieve <strong>dream qualifications</strong> and <strong>degrees</strong>.</p> <p>What sets Aayushi apart is her <strong>holistic approach</strong>. Beyond academic success, she aims to cultivate a <strong>positive transformation</strong>, helping individuals become <strong>better versions</strong> of themselves for a <strong>successful</strong> and <strong>fulfilling life</strong> and <strong>career</strong>. Having <strong>influenced</strong> over <strong>25,000+</strong> through her training programs, Aayushi stands as an influential personality who left a corporate career to shape a brighter educational future for others.</p> <p>She is on a <strong>mission to help 1 lakh students</strong> to achieve their goals and transforming their lives through her training workshops in the next two years. She aims to contribute to Nation building by creating a <strong>powerful army of students </strong>with a warrior like confidence.</p>",
        image: "https://lh3.googleusercontent.com/m5v_0rrQvH4MkXZ9GJnvdwjlh6ckdnBDeWf2dHlQJf3drxngE1AJz7LSgixf0vDtnxTh3WqZor9v2H5HYFg5Nu2ERk8R0aJp5Q=w968",
        video: ""
    },
    benefits : {
        title: [
            { id: 2, text: "Benefits" },
            { id: 1, text: " from this workshop", className: "text-white-color"},
        ],
        points: [
            { value: 'No mobile addiction', description: 'Get rid of mobile addiction forever' },
            { value: 'Wake up @5AM', description: 'wake up in BRAHMA MAHURATA every day like a clock' },
            { value: 'Overcome Distractions', description: 'overcome PROCASTINATION AND DISTRACTIONS while studying' },
            { value: 'Magical Formula', description: 'Magical Formula to Score More and Study Less' },
            { value: "Toppers's Mind", description: 'The hidden secrets of Toppers mind' },
          ]
    },
    curriculum : {
    },
    timeline: {
        title: [
            { id: 3, text: "What ", className: "text-white-color"},
            { id: 2, text: "you will learn" },
            { id: 1, text: " from this workshop", className: "text-white-color"},
        ],
        image: "https://media.giphy.com/media/1kTRymRZp5CA3f7G8u/giphy.gif?cid=790b7611iwv2q5qb13oh857c3ufnb48sen7fgqp1j3di9myu&ep=v1_gifs_search&rid=giphy.gif&ct=g",
        visibilityLimit: 6,
        fillColor: '#fff',
        content: [
            {
              timeline: "Day 1",
              title: "Get Started: Take The Most Important Step towards your goals",
              theme: 'dark',
              description:
                "<p>Day-1 session will help you get started and build strong foundation for the Masterclass. You'll also come to know what have you been doing wrong at these times.</p> <p>As a result of this session, you'll get lot of clarity on your goals and motivation to work on your goals tirelessly.</p>",
              imageUrl: "https://toppersnotes.b-cdn.net/Landing%20page%20files/Ayushi/image/day1.webp"
            },
            {
              timeline: "Day 2",
              title: "Wake Up at 5:00AM Like A Clock",
              theme: 'dark',
              description:
                "<p>Day-2 session will transform you into an early riser to help you hit your goal faster.</p> <p>No matter how many times you've failed in getting up early in the morning, after this session you'll jump out of the bed at 5:00am like a clock every morning for the rest of your life.</p>",
              imageUrl: "https://toppersnotes.b-cdn.net/Landing%20page%20files/Ayushi/image/day2.webp",
            },
            {
              timeline: "Day 3",
              title: "Get Focused: Overcome Distractions and Procrastination",
              theme: 'dark',
              description:
                "<p>Day-3 session will get you laser like focus on studies. This will help you get rid of Mobile phone addiction completely.</p> <p>After this session, you'll neither procrastinate nor get distracted by negative thoughts. Your efficiency and concentration level will get improved drastically.</p>",
              imageUrl: "https://toppersnotes.b-cdn.net/Landing%20page%20files/Ayushi/image/day3.webp",
            },
            {
              timeline: "Day 4",
              title: "Bang With: Score More Study Less MasterPlan",
              theme: 'light',
              description:
                "<p>Day-4 is the most powerful and a breakthrough session in terms of helping students clearing their mental blocks/ myths about studies. It gives them much clarity on their approach towards studies.</p> <p>As a result of this session, you'll be able to strategise your studies confidently not only score more in exams but also to save your time and use it wisely .</p>",
              imageUrl: "https://toppersnotes.b-cdn.net/Landing%20page%20files/Ayushi/image/day4.webp",
            },
            {
              timeline: "Day 5",
              title: "The Hidden Secret of Topper's Mind",
              theme: 'light',
              description:
                "<p>Day-5 session will help you understand what differentiates a topper and an average performer.</p> <p>As a result of this session, you'll get a path to become a better and more powerful version of yourself.</p>",
              imageUrl: "https://toppersnotes.b-cdn.net/Landing%20page%20files/Ayushi/image/day5.webp"
            },
            // Add more events as needed
          ]
    },
    bonuses: {
        title: [
            { id: 2, text: "Free" , className: "text-white-color"},
            { id: 3, text: " Bonuses"},
            { id: 4, text: " worth", className: "text-white-color"},
            { id: 1, text: " ₹4999"},
        ],
        content: [
        {
          id: 5,
          title: "Bonus#1: An Exclusive Guided Meditation To Manifest Your Desires",
          bonusImage:
            "https://lh3.googleusercontent.com/0GCCscLCj_4ix_KbxEchpDlbX1rZQmJdrpazDX3kOgJeyLhfKW91s2qvyImbxQBdAHU7ZzmElpfp2Bq6UWSduUenVDB1MG-XIAU=w1064",
        },
        {
          id: 6,
          title: "Bonus#2: LIVE Q&A sessions to answer all your queries",
          bonusImage:
            "https://lh3.googleusercontent.com/_EIGg9JZ1Ejuvm95NoOc2U8Ooce6hQgBPHnqBrU7FiWosj3g3tTjJfCnB3WvcPYJwN2s_euEc513cZsta0HMmF_ED6HssFwhUec=w1064",
        },
        {
            id: 7,
            title: "Bonus#3: Certificate of Appreciation",
            bonusImage: "https://lh3.googleusercontent.com/MK_NOFC3kRx6OmvuxGbD1siTm2lNdMvkF1PrlVgrccR366RPTQyn57BSpvvp5mmP6vdYcvgfJdF3GNSosHkBh7awDfQdx4lLuQ=w1064"
        }
      ]
    },
    mentors: {
    },
    texttestimonials: {
        title: [
            { id: 3, text: "What ", className: "text-white-color"},
            { id: 2, text: "our past students" },
            { id: 1, text: " are saying", className: "text-white-color"},
        ],
        useSlider: false,
        content: [
        {
          name: 'Dharshne Senbagam',
          post: 'CA Final Student',
          image: 'https://user-images.trustpilot.com/6605aa957d1fce00124e075a/73x73.png',
          feedback: "<div > <h2 >Worth the money spent</h2> <p >Highly informative workshop which I would recommend for every student. It was an eye opening moment for a CA inter repeater like me. I badly regret for not knowing about mam and her workshop earlier. But I feel lucky that I was able to attend her session atleast now. My confidence level has boosted a lot. Gained immense knowledge by spending just few rupees. Lovely recorded and live classes.</p> <p ><strong>Date of experience:</strong> March 28, 2024</p> </div>",
          rating: 5
        },
        {
        name: 'Gayathri Murugan',
        // post: 'BCA Student',
        image: 'https://user-images.trustpilot.com/6619f4f4427b7a0011a69d47/73x73.png',
        feedback: "<div ><h2 >Life transformation</h2><p >Mam really excited and overwhelmed by the way the classes and it's valuable inputs are useful<br />100 times Worth of the money and time spent <br />I repent for knowing about this 5 days workshop very late</p><p ><strong>Date of experience:</strong> April 13, 2024</p></div>",
        rating: 5
        },
      {
        name: 'Nahush Junnarkar',
        // post: '12th Board, HR College, Mumbai',
        image: 'https://user-images.trustpilot.com/65749c6a51045c0012c01252/73x73.png',
        feedback: "<div> <h2>The session was very helpful for me</h2> <p>The session was very helpful for me, especially the early riser session. I struggled a lot to wake up early but the hacks shared in the session were scientifically correct and also practical. Also the part where <strong>CA Ayushi Garg</strong> tells about science behind addiction and how to overcome mobile addiction was mind blowing.</p> <p><strong>Date of experience:</strong> December 09, 2023</p> </div>",
        rating: 5
        },
        {
        name: 'Keerthi Pradeep',
        // post: 'CA IPCC Student',
        image: 'https://user-images.trustpilot.com/6562bedb40646e00124ae942/73x73.png',
        feedback: "<div > <h2 >5 days masterclass was an amazing&hellip;</h2> <p >5 days masterclass was an amazing experience for me.<br />Even the introduction was filled with positivity, these classes make me feel that i will be able to clear the exam and i'm capable for that. each day was very helpful and kept motivated to study .Eagerly waiting for day 5 live class. Thank you so much for this oppurtunity . I LOVED IT</p> <p ><strong>Date of experience:</strong> November 26, 2023</p> </div>",
        rating: 5
        },
      {
        name: 'Shruti Batra',
        // post: 'CA Final Student',
        image: "https://user-images.trustpilot.com/65b5395e0395ff001241aa96/73x73.png",
        feedback: "<div > <h2 >A Great help for those who need guidance and motivation&hellip;</h2> <p >A Great help for those who need guidance in their student journey. This Masterclass helped me in knowing all the pros and cons about my study schedule, planning etc. It has motivated me a lot. Helps in improving productivity. Thanks to our mentor.</p> <p ><strong>Date of experience:</strong> January 26, 2024</p> </div>",
        rating: 5
  
  
      },
      {
        name: 'Supriya Aravind',
        // post: 'CA Final Student',
        image: "https://user-images.trustpilot.com/6619653d3d419800121115d6/73x73.png",
        feedback: "<div > <h2 >Must attended workshop to know where&hellip;</h2> <p >Must attended workshop to know where you are going wrong in study technique. U will get to know the purpose of why towards your goal. Once I know the purpose you will be unstoppable.</p> <p ><strong>Date of experience:</strong> April 12, 2024</p> </div>",
        rating: 5
      },
        ]
        },
        videotestimonials: {
    title: [
      { id: 3, text: "Video Diaries:  ", className: "text-white-color" },
      { id: 2, text: "Journeys to Success" },
      
      ],
    style: 'card', // 'image' / 'card'
    content: [
      {
        thumbnail: 'https://d14a823tufvajd.cloudfront.net/images/BwNhTHUq0KCRcBekQwTK.webp',
        videoUrl: 'https://iframe.mediadelivery.net/embed/228263/c09806b6-22cc-4445-bf35-d88be9938ead',
        title: 'This workshop has been very helpful',
        name: 'Komal Jain',
        // post: '',
        },
      {
        thumbnail: 'https://d14a823tufvajd.cloudfront.net/images/3G48w1KnPHKqXxTB5N8n.webp',
        videoUrl: 'https://iframe.mediadelivery.net/embed/228263/afb378d1-b7c3-4a86-8809-da9633b94dff',
        title: "Aayushi ma'am teaches really well",
        name: 'Madhumita Choudhary',
        // post: '',
      },
      {
        thumbnail: 'https://d14a823tufvajd.cloudfront.net/images/MXkUmJzqkpgT3yqeuBqE.webp',
        videoUrl: 'https://iframe.mediadelivery.net/embed/228263/2bbf7a91-8232-439d-a513-421b11546a70',
        title: 'This session changed me completely!',
        name: 'Nandini Patel',
        // post: '',
      },
      {
        thumbnail: 'https://d14a823tufvajd.cloudfront.net/images/5inWpf1zJRG1F9Pjgfvt.webp',
        videoUrl: 'https://iframe.mediadelivery.net/embed/228263/49dd4f2a-725f-4133-925d-6380c1d6a760',
        title: 'This session helped me gain my lost confidence',
        name: 'Palak Ghanchi',
        // post: '',
      },
      {
        thumbnail: 'https://d14a823tufvajd.cloudfront.net/images/qCKtRz5zsiZFrNkE05m4.webp',
        videoUrl: 'https://iframe.mediadelivery.net/embed/228263/06169fd1-ecb5-4e76-b80c-611d04ba2613',
        title: 'I learned priceless lessons in this 5 day workshop',
        name: 'Rajeshwari',
        // post: '',
      }
    ]
  },
  faqs: {
    title: [
      { id: 2, text: "FAQs" },
      { id: 1, text: " here’s everything you may ask...", className: "text-white-color" },
    ],
    expandedKeys: ["0", "1", "5"],
    content: [
      
      {
        key: "1",
        question: "What will be the duration of sessions?",
        answer: "<p>The duration of each session will be around 60 minutes</p>",
      },
      {
        key: "2",
        question: "What time the session will start everyday?",
        answer: "<p>We will send you pre-recorded sessions which you can watch anytime during the day as per your convenience. Recordings will be valid for 24 hours.</p>"
      },
      {
        key: "3",
        question: "Which language the sessions are conducted?",
        answer: "<p>English is the primary language used in the sessions.</p>"
      },
      {
        key: "4",
        question: "Which platform is used for conducting sessions?",
        answer: "<p>LIVE Q&A Sessions will be conducted on zoom.</p>",
      },
      {
        key: "5",
        question: "Can I get a refund if I don't like the workshop?",
        answer: "<p>Aayushi is a renowned <strong>Study &amp; Career Coach</strong> who has helped <strong>15,000+ students</strong> across the country through her training programs and workshops. <strong>We have a 4.8 Google rating, 550+ positive reviews on Trustpilot and 1,000+ recommendations for this workshop itself.</strong> The workshop is <strong>priced very low</strong> so that <strong>maximum students can benefi</strong>t. You can research about the mentor and read the testimonials before making a purchase. We do not offer refunds for any reason. However, if you are not able to attend the training program for some reason, we can reschedule your batch and you just have to drop a mail at `scoremorestudyless@gmail.com` within 3 days of purchase.</p>",
      },
    ]
  }
};